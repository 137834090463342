import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.less';
import cx from 'classnames';
import ErrorFace from '../../graphics/oops.svg';

const Text = (props) => {
  const { className, error, ...otherProps } = props;
  return (
    <div className={cx(styles.fieldWrapper, className)}>
      <input
        type="text"
        className={styles.text}
        aria-invalid={!!error}
        {...otherProps}
      />
      {error && (
        <span className={styles.errorFace}>
          <ErrorFace />
        </span>
      )}
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};

Text.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  onBlur: PropTypes.func,
};

export default Text;
