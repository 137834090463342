import React from 'react';
import PropTypes from 'prop-types';
import styles from './presentationPage.less';
import PromotedQuestion from './promotedQuestion';
import Logo from '../../graphics/logo.svg';
import AnimatedList from '../animatedList/animatedList';
import { QuestionPresentationMode } from '../question';

const LiveScreen = ({ eventName, eventCode, questions, questionOnAir }) => {
  const containerClassName = questionOnAir
    ? styles.presentationWithPromotedQuestion
    : styles.presentationWithoutPromotedQuestion;

  return (
    <div className={containerClassName}>
      {questionOnAir && (
        <div className={styles.promotedQuestion}>
          <PromotedQuestion {...questionOnAir} />
        </div>
      )}
      <div className={styles.promoWrapper}>
        <div>
          <div
            className={
              questionOnAir ? styles.promoWhenQuestionOnAir : styles.promo
            }
          >
            <div>
              <h2 className="presentation-page__promo-title">{eventName}</h2>
              <div className="presentation-page__promo-description">
                Join at www.questup.io
              </div>
              <div className="presentation-page__promo-description">
                Enter #{eventCode}
              </div>
            </div>
          </div>
          <div className={styles.poweredBy}>
            <div>Powered by</div>
            <Logo className={styles.poweredByLogo} />
          </div>
        </div>
      </div>
      <div className={styles.questions}>
        <AnimatedList
          questions={questions}
          renderListItem={(q) => (
            <QuestionPresentationMode
              key={q.id}
              id={q.id}
              author={q.author}
              question={q.question}
              votes={q.votes}
            />
          )}
        />
      </div>
    </div>
  );
};

LiveScreen.propTypes = {
  eventName: PropTypes.string.isRequired,
  eventSlogan: PropTypes.string.isRequired,
  eventCode: PropTypes.string.isRequired,
  questions: PropTypes.array,
  questionOnAir: PropTypes.object,
};

export default LiveScreen;
