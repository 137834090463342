import React from 'react';
import section from '../../homePage.less';
import styles from './footer.less';
import EventCodeForm from '../../eventCode';
import { Link } from '@reach/router';
import LogoIcon from '../../../../graphics/logo.svg';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const SectionFooter = ({ user }) => {
  return (
    <section className={styles.footer}>
      <div className={section.sectionContent}>
        <div>
          <LogoIcon className={styles.logo} />
          <p className={styles.leadParagraph}>
            It’s so easy that we don’t need a footer
          </p>
        </div>
        <div className={styles.eventCodeAndLinks}>
          <EventCodeForm />
          {!user.user && (
            <div className={styles.linkContainer}>
              <Link className={styles.link} to="/admin">
                Login
              </Link>
              <Link className={styles.link} to="/register">
                Get started
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

SectionFooter.propTypes = {
  user: PropTypes.object,
};

export default connect(mapStateToProps)(SectionFooter);
