import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.less';

const Label = ({ children, helpText, ...otherProps }) => {
  return (
    <label className={styles.label} {...otherProps}>
      {children}
      {helpText && <span className={styles.helpText}>{helpText}</span>}
    </label>
  );
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  helpText: PropTypes.string,
};

export default Label;
