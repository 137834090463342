import { createSelector } from 'reselect';

const userSelector = (state) => state.user.user;

export const getUserEmail = createSelector(userSelector, (user) => user.email);

export const getUserFullName = createSelector(
  userSelector,
  (user) => `${user.firstName} ${user.lastName}`
);

export const getUser = createSelector(userSelector, (user) => {
  if (!user) {
    return undefined;
  }
  return {
    id: user.id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    fullName: `${user.firstName} ${user.lastName}`,
    firstLogin: user.firstLogin,
  };
});
