const getJson = (response) => {
  if (response.ok) {
    return response.json();
  }
  return Promise.reject(response);
};

export const getEvent = (eventId) => {
  return fetch(`${REST_API_HOST}/event/${eventId}`, {
    credentials: 'include',
  }).then(getJson);
};

export const getEventAsAdmin = (eventId) => {
  return fetch(`${REST_API_HOST}/admin/event/${eventId}`, {
    credentials: 'include',
  }).then(getJson);
};

export const updateEventName = (name, eventId) => {
  return fetch(`${REST_API_HOST}/admin/event/${eventId}`, {
    credentials: 'include',
    method: 'put',
    body: JSON.stringify({ name }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(getJson);
};

export const sendEmailToFriend = (email) => {
  return fetch(`${REST_API_HOST}/admin/mail/promote`, {
    credentials: 'include',
    method: 'post',
    body: JSON.stringify({ email }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(getJson);
};

export const resetPassword = (formValues) => {
  return fetch(`${REST_API_HOST}/user/password-reset`, {
    credentials: 'include',
    method: 'post',
    body: JSON.stringify(formValues),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(getJson);
};

export const changePassword = (formValues, token) => {
  return fetch(`${REST_API_HOST}/user/password-reset/change`, {
    credentials: 'include',
    method: 'post',
    body: JSON.stringify({ password: formValues.password, token }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(getJson);
};

export const checkTokenValidity = (token) => {
  return fetch(`${REST_API_HOST}/user/password-reset/token-validity`, {
    credentials: 'include',
    method: 'post',
    body: JSON.stringify({ token }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(getJson);
};

export const updateName = ({ firstName, lastName }) => {
  return fetch(`${REST_API_HOST}/user/name`, {
    credentials: 'include',
    method: 'put',
    body: JSON.stringify({ firstName, lastName }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(getJson);
};

export const updatePassword = ({ currentPassword, newPassword }) => {
  return fetch(`${REST_API_HOST}/user/password`, {
    credentials: 'include',
    method: 'put',
    body: JSON.stringify({ currentPassword, newPassword }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(getJson);
};

export const resetEvent = (eventId) => {
  return fetch(`${REST_API_HOST}/admin/event/${eventId}/reset`, {
    credentials: 'include',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(getJson);
};

export const deleteEvent = (eventId) => {
  return fetch(`${REST_API_HOST}/admin/event/${eventId}`, {
    credentials: 'include',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(getJson);
};

export const register = (formData) => {
  return fetch(`${REST_API_HOST}/user/register`, {
    credentials: 'include',
    method: 'post',
    body: JSON.stringify(formData),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(getJson);
};

export const askQuestion = (eventId, formData) => {
  return fetch(`${REST_API_HOST}/event/${eventId}/ask`, {
    credentials: 'include',
    method: 'post',
    body: JSON.stringify(formData),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(getJson);
};

export const shareQuestion = (eventId, questionId) => {
  return fetch(
    `${REST_API_HOST}/admin/event/${eventId}/share-question/${questionId}`,
    {
      credentials: 'include',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ).then(getJson);
};

export const vote = (eventId, questionId) => {
  return fetch(`${REST_API_HOST}/event/${eventId}/vote/${questionId}`, {
    credentials: 'include',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getUser = () => {
  return fetch(`${REST_API_HOST}/user`, {
    credentials: 'include',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export const isEmailTaken = (email) => {
  return fetch(`${REST_API_HOST}/user/email-taken`, {
    body: JSON.stringify({ email }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export const login = (formData) => {
  return fetch(`${REST_API_HOST}/user/login/`, {
    credentials: 'include',
    method: 'post',
    body: JSON.stringify(formData),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export const getEventWithCode = (code) => {
  return fetch(`${REST_API_HOST}/event/code/${code}`, {
    credentials: 'include',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const createEvent = (formData) => {
  return fetch(`${REST_API_HOST}/admin/event/create`, {
    credentials: 'include',
    method: 'post',
    body: JSON.stringify(formData),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export const setEventTitle = (title) => {
  return fetch(`${REST_API_HOST}/api/event/title`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ title }),
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export const setEventDescription = (description) => {
  return fetch(`${REST_API_HOST}/api/event/description`, {
    method: 'PUT',
    body: JSON.stringify(description),
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export const deleteQuestion = (questionId) => {
  return fetch(`${REST_API_HOST}/admin/question/${questionId}`, {
    method: 'DELETE',
    credentials: 'include',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export const fetchNumberOfRegisteredUsers = () => {
  return fetch(`${REST_API_HOST}/stats/number-of-registered-users`, {
    credentials: 'include',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export const fetchEventCount = () => {
  return fetch(`${REST_API_HOST}/stats/event-count`, {
    credentials: 'include',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export const fetchQuestionCount = () => {
  return fetch(`${REST_API_HOST}/stats/question-count`, {
    credentials: 'include',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export const fetchLatestRegisteredUsers = () => {
  return fetch(`${REST_API_HOST}/stats/latest-registered-users`, {
    credentials: 'include',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export const fetchLatestQuestions = () => {
  return fetch(`${REST_API_HOST}/stats/latest-questions`, {
    credentials: 'include',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export const fetchLatestCreatedEvents = () => {
  return fetch(`${REST_API_HOST}/stats/latest-created-events`, {
    credentials: 'include',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export const fetchConnectedWsClientsCount = () => {
  return fetch(`${REST_API_HOST}/stats/connected-ws-clients`, {
    credentials: 'include',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  });
};

export const subscribeToProReleaseNotification = (email) => {
  return fetch(`${REST_API_HOST}/app/subscribe-to-pro-notification`, {
    credentials: 'include',
    method: 'post',
    body: JSON.stringify({ email }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(getJson);
};
