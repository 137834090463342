import React from 'react';
import { Link } from '@reach/router';

// eslint-disable-next-line react/prop-types
const NavLink = ({ activeClassName, className, ...otherProps }) => {
  return (
    <Link
      {...otherProps}
      getProps={({ isCurrent }) => {
        // the object returned here is passed to the
        // anchor element's props
        return {
          className: isCurrent ? activeClassName : className,
        };
      }}
    />
  );
};

export default NavLink;
