import React from 'react';
import PropTypes from 'prop-types';
import styles from './presentationPage.less';
import EmptyScreen from './emptyScreen';
import LiveScreen from './liveScreen';
import ContextMenu from './contextMenu';
import { sortByVotes } from '../../utils/questions';

class PresentationPage extends React.Component {
  render() {
    const {
      eventName,
      eventId,
      eventSlogan,
      eventPinCode,
      questionOnAir,
      upcomingQuestions,
    } = this.props;

    const sortedQuestions = sortByVotes(upcomingQuestions);
    const hasQuestions =
      sortedQuestions.length > 0 || questionOnAir !== undefined;
    return (
      <div className={styles.presentationPageContainer}>
        {!hasQuestions && (
          <EmptyScreen
            eventName={eventName}
            eventSlogan={eventSlogan}
            eventPinCode={eventPinCode}
          />
        )}
        {hasQuestions && (
          <LiveScreen
            eventName={eventName}
            eventId={eventId}
            eventSlogan={eventSlogan}
            eventCode={eventPinCode}
            questions={sortedQuestions}
            questionOnAir={questionOnAir}
          />
        )}
        <ContextMenu eventId={eventId} />
      </div>
    );
  }
}

PresentationPage.propTypes = {
  eventName: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  eventSlogan: PropTypes.string,
  eventPinCode: PropTypes.string.isRequired,
  questionOnAir: PropTypes.shape({
    id: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    votes: PropTypes.number.isRequired,
  }),
  upcomingQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      votes: PropTypes.number.isRequired,
    })
  ),
};

PresentationPage.defaultProps = {
  upcomingQuestions: [],
};

export default PresentationPage;
