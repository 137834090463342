import React, { useEffect, useState } from 'react';
import styles from './suspenseFallback.less';

const SuspenseFallback = () => {
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoadingIndicator(true);
    }, 250);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className={styles.suspense}>
      {showLoadingIndicator && (
        <div className={styles.loader}>
          Loading <span className={styles.dotOne}>.</span>
          <span className={styles.dotTwo}>.</span>
          <span className={styles.dotThree}>.</span>
        </div>
      )}
    </div>
  );
};

export default SuspenseFallback;
