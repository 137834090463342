import React from 'react';
import PropTypes from 'prop-types';
import styles from './pricing.less';

const Price = ({ label, price }) => {
  return (
    <h3 className={styles.featuresHeading}>
      <span className={styles.version}>{label}</span>
      <span className={styles.currency}>$</span>
      <span className={styles.price}>{price}</span>
    </h3>
  );
};

Price.propTypes = {
  label: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};

export default Price;
