import React from 'react';
import PropTypes from 'prop-types';
import styles from './pricing.less';
import Checkmark from '../../graphics/checkmark.svg';

// eslint-disable-next-line react/prop-types
export const Feature = ({ children }) => (
  <li>
    <Checkmark className={styles.checkmark} />
    {children}
  </li>
);

const Features = ({ children }) => {
  return <ul className={styles.features}>{children}</ul>;
};

Features.propTypes = {
  children: PropTypes.array,
};

export default Features;
