import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.less';
import cx from 'classnames';
import ErrorFace from '../../graphics/oops.svg';
import VisibleIcon from '../../graphics/pw-hide.svg';
import HiddenIcon from '../../graphics/pw-show.svg';

const Password = (props) => {
  const [visible, setVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setVisible(!visible);
  };
  const { className, error, ...otherProps } = props;
  return (
    <div className={cx(styles.fieldWrapper, className)}>
      <input
        type={visible ? 'text' : 'password'}
        className={styles.password}
        aria-invalid={!!error}
        {...otherProps}
      />
      {!error && (
        <button
          type="button"
          className={styles.passwordVisibilityToggle}
          onClick={togglePasswordVisibility}
          aria-label="Toggle password visibility"
        >
          {visible ? <HiddenIcon /> : <VisibleIcon />}
        </button>
      )}
      {error && (
        <span className={styles.errorFace}>
          <ErrorFace />
        </span>
      )}
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};

Password.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  onBlur: PropTypes.func,
};

export default Password;
