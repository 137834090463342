import React from 'react';
import styles from './useCases.less';
import section from '../../homePage.less';
import conferencePhoto from '../../../../graphics/conference_questup.jpg';
import conferencePhotoWebp from '../../../../graphics/conference_questup.webp';
import internalMeetingsPhoto from '../../../../graphics/internal-meetings.jpg';
import internalMeetingsPhotoWebp from '../../../../graphics/internal-meetings.webp';
import generalAssemblyPhoto from '../../../../graphics/general-assembly.jpg';

const UseCases = () => {
  return (
    <section className={styles.useCases} id="use-cases">
      <div className={section.sectionContent}>
        <h2 className={styles.heading}>Use cases</h2>
        <ul className={styles.examples}>
          <li className={styles.conference}>
            <picture className={styles.image}>
              <source
                type="image/webp"
                srcSet={conferencePhotoWebp}
                width="570"
                height="408"
              />
              <img
                loading="lazy"
                className={styles.image}
                src={conferencePhoto}
                alt=""
                width="570"
                height="408"
              />
            </picture>
            <div className={styles.text}>
              <h3 className={styles.title}>Conference</h3>
              <p className={styles.desc}>
                Use Questup at your conference, gathering and highlighting good
                questions from your crowd.
              </p>
            </div>
          </li>
          <li className={styles.internalMeeting}>
            <picture className={styles.image}>
              <source
                type="image/webp"
                srcSet={internalMeetingsPhotoWebp}
                width="370"
                height="246"
              />
              <img
                loading="lazy"
                className={styles.image}
                src={internalMeetingsPhoto}
                alt=""
                width="370"
                height="246"
              />
            </picture>
            <div className={styles.text}>
              <h3 className={styles.title}>Internal meetings</h3>
              <p className={styles.desc}>
                Use questup to spark an open and good discussion with your team
              </p>
            </div>
          </li>
          <li className={styles.generalAssembly}>
            <img
              loading="lazy"
              className={styles.image}
              src={generalAssemblyPhoto}
              alt=""
              width="334"
              height="204"
            />
            <div className={styles.text}>
              <h3 className={styles.title}>Annual general meeting</h3>
              <p className={styles.desc}>
                Gather questions from your members both on and off-site.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

UseCases.propTypes = {};

export default UseCases;
