import React from 'react';
import PropTypes from 'prop-types';
import styles from './questionPresentation.less';
import { getVoteSuffix } from '../utils';

const Question = (props) => {
  const { author, question, votes, isLive } = props;
  return (
    <div className={isLive ? styles.containerLive : styles.container}>
      <div className={styles.content}>
        <div className={styles.author}>
          <div className={styles.name}>{author}</div>
          <div className={isLive ? styles.votesLive : styles.votes}>
            {`${votes} ${getVoteSuffix(votes)}`}
          </div>
        </div>
        <div className={styles.question}>{question}</div>
      </div>
    </div>
  );
};

Question.propTypes = {
  author: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  votes: PropTypes.number.isRequired,
  isLive: PropTypes.bool,
};

export default Question;
