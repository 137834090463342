import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../../pages/home/sections/footer/footer';
import AppNavBar from '../../components/appNavBar/appNavBar';
import styles from './defaultLayout.less';
import { Helmet } from 'react-helmet';

const DefaultLayout = ({ pageTitle, children }) => {
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <section className={styles.siteContainer}>
        <AppNavBar />
        <div className={styles.pageContainer}>{children}</div>
        <Footer />
      </section>
    </>
  );
};

DefaultLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default DefaultLayout;
