import React, { Suspense } from 'react';
import chunkLoader from './utils/chunkLoader';
import { hot } from 'react-hot-loader';
import { Location, Router } from '@reach/router';
import SuspenseFallback from './components/suspenseFallback/suspenseFallback';
import Home from './pages/home/homePage';
import { ScrollToTop } from './components/ScrollToTop';
import Pricing from './pages/pricing/pricing';
import PresentationPage from './pages/presentation/presentationPage.js';
import UseCases from './pages/useCases/useCases';
import Faq from './pages/faq/faq';
import Login from './pages/login/login';
import Register from './pages/register/register';

const Event = React.lazy(() =>
  chunkLoader(() =>
    import(
      /* webpackChunkName: "Event", webpackPreload: true */ './pages/event/event'
    )
  )
);

const Admin = React.lazy(() =>
  chunkLoader(() =>
    import(
      /* webpackChunkName: "Admin", webpackPreload: true */ './admin/admin'
    )
  )
);

const ForgottenPassword = React.lazy(() =>
  chunkLoader(() =>
    import(
      /* webpackChunkName: "ForgottenPassword", webpackPreload: true */ './pages/forgotPassword/forgotPassword'
    )
  )
);

const PasswordReset = React.lazy(() =>
  chunkLoader(() =>
    import(
      /* webpackChunkName: "PasswordReset", webpackPreload: true */ './pages/passwordReset/passwordReset'
    )
  )
);

const Privacy = React.lazy(() =>
  chunkLoader(() =>
    import(
      /* webpackChunkName: "Privacy", webpackPreload: true */ './pages/privacy/privacy'
    )
  )
);

const TermsOfService = React.lazy(() =>
  chunkLoader(() =>
    import(
      /* webpackChunkName: "TermsOfService", webpackPreload: true */ './pages/termsOfService/termsOfService'
    )
  )
);

const PageNotFound = React.lazy(() =>
  chunkLoader(() =>
    import(
      /* webpackChunkName: "PageNotFound", webpackPreload: true */ './pages/404/404'
    )
  )
);

const Dashboard = React.lazy(() =>
  chunkLoader(() =>
    import(
      /* webpackChunkName: "Dashboard", webpackPreload: true */ './pages/dashboard/dashboard'
    )
  )
);

function App() {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <Location>
        <Router>
          <ScrollToTop path="/">
            <Home path="/" />
            <Event path="/event/:eventId" />
            <PresentationPage path="/event/:eventId/presentation" />
            <Register path="/register" />
            <Login path="/login" />
            <ForgottenPassword path="/forgotten-password" />
            <PasswordReset path="/password-reset" />
            <Privacy path="/privacy" />
            <TermsOfService path="/terms-of-service" />
            <Pricing path="/pricing" />
            <UseCases path="/use-cases" />
            <Faq path="/faq" />
            <Dashboard path="/dashboard" />

            <Admin path="/admin/*" />

            <PageNotFound default />
          </ScrollToTop>
        </Router>
      </Location>
    </Suspense>
  );
}

export default hot(module)(App);
