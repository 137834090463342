import React from 'react';
import styles from './howItWorks.less';
import section from '../../homePage.less';
import img1 from './how-it-works-1.png';
import img2 from './how-it-works-2.png';
import img3 from './how-it-works-3.png';

const HowItWorks = () => {
  return (
    <section className={styles.howItWorks}>
      <div>
        <span />
        <span />
        <span />
      </div>
      <div className={section.sectionContent}>
        <h2 className={styles.heading}>How it works</h2>
        <div className={styles.scrollContainer}>
          <ol className={styles.steps}>
            <li className={styles.step}>
              <img className={styles.image} src={img1} alt="" />
              <div className={styles.title}>Create an event</div>
              <p className={styles.desc}>With few clicks</p>
            </li>
            <li className={styles.step}>
              <img className={styles.image} src={img2} alt="" />
              <div className={styles.title}>Get questions</div>
              <p className={styles.desc}>
                Your participants go to your event and start asking.
              </p>
            </li>
            <li className={styles.step}>
              <img className={styles.image} src={img3} alt="" />
              <div className={styles.title}>Happy participants</div>
              <p className={styles.desc}>
                Both you and your participants enjoy the conversation
              </p>
            </li>
          </ol>
        </div>
      </div>
    </section>
  );
};

HowItWorks.propTypes = {};

export default HowItWorks;
