class EventWebsocket {
  constructor(conf) {
    const {
      eventId,
      onEventDetailsChange,
      onHighlightQuestion,
      highLightQuestionCacheTime,
      onQuestionsChange,
      questionsChangeCacehTime,
      eventDontExist,
    } = conf;
    this.eventId = eventId;
    this.onEventDetailsChange = onEventDetailsChange;
    this.onHighlightQuestion = onHighlightQuestion;
    this.highLightQuestionCacheTime = highLightQuestionCacheTime;
    this.onQuestionsChange = onQuestionsChange;
    this.questionsChangeCacehTime = questionsChangeCacehTime;
    this.eventDontExist = eventDontExist;

    // Event data for caching
    this.event = {
      id: eventId,
    };
  }

  start() {
    // Prevent double connections
    if (!this.socket || this.socket.readyState === 3) {
      console.log(`reconnecting to ws`);
      this.socket = new WebSocket(
        `${API_HOST}/ws/event?eventId=${this.eventId}`
      );
      this.socket.onopen = () => {
        // do nothing? console.log(`ws conn open`);
      };
      this.socket.onmessage = (evt) => this.onWsMessageReceived(evt);
      this.socket.onclose = () => {
        // Do nothing? Maybe log it? Maybe reconnect console.log(`ws conn closed`);
      };
    }
    if (this.pauseUpdates) {
      this.questionTimer = setTimeout(() => {
        this.onQuestionsChange(this.event.questions);
        this.questionTimer = undefined;
      }, 5000);
      this.pauseUpdates = false;
    }
  }

  pause() {
    clearTimeout(this.questionTimer);
    this.pauseUpdates = true;
  }

  stop() {
    console.log(`disconnect ws`);
    this.socket.close();
  }

  onWsMessageReceived(evt) {
    const { name, code, questions } = JSON.parse(evt.data);

    if (!name) {
      this.eventDontExist();
      return;
    }

    // Update event details if needed
    if (name !== this.event.name || code !== this.event.code) {
      this.event.name = name;
      this.event.code = code;
      if (!this.pauseUpdates) {
        this.onEventDetailsChange({ name, code });
      }
    }

    if (questions.length === 0) {
      this.onHighlightQuestion(undefined);
      this.onQuestionsChange(questions);
      return;
    }

    // Update active question if needed
    const activeQuestion = questions.find((q) => q.active);
    this.event.questions = questions;
    if (
      (activeQuestion && !this.event.activeQuestion) ||
      (activeQuestion && activeQuestion.id !== this.event.activeQuestion.id)
    ) {
      this.event.activeQuestion = activeQuestion;
      if (!this.pauseUpdates) {
        console.log(`WS notify active question updated`);
        clearTimeout(this.questionTimer);
        this.onHighlightQuestion(activeQuestion);
        this.onQuestionsChange(questions);
        // pushet gjennom endring, vent 5 sekunder før ny endring godtas
        // med mindre det er aktivt spm

        // set up timer
        this.questionTimer = setTimeout(() => {
          this.onQuestionsChange(this.event.questions);
          this.questionTimer = undefined;
        }, 5000);
        return; // does it break here?
      }
    }
    console.log(`got here`);

    // No timer set, update immediately
    if (!this.questionTimer && !this.pauseUpdates) {
      this.onQuestionsChange(questions);
      // TODO denne logikken er feil!
      this.questionTimer = setTimeout(() => {
        this.onQuestionsChange(this.event.questions);
        this.questionTimer = undefined;
      }, 5000);
    }

    /*
    // will probably need som kind of timestamp here to avoid race conditions.
    // is it possible to receive the new status, THEN the old because of netwoek issues?
    // - what has changed? (event details, questionOnAIr or questions?)
    // 1. if event details, update and call function.
    2. questionOnAir changed.
      2.1 - set it
      2.2 - is there a timer?
              - no?
                - is it paused? do nothing
                - not paused - notify subscriber
              - yes? do nothing, as the timer will handle it
     3. questions changed
        3.1 - set it.
        3.2 - is there a timer?
          no? notify subscriber
          yes? do nothing
     4. pause()
        4.1 - stop any timers.
        4.2 - set a flag, PAUSED
     5. start
        5.1 - connect, add event listeners
        5.2 - notify of initially received data
     6. stop
        6.1 set flag: STOPPED
        6.1 stop timers
        6.2 discconnect event listners



     */
  }
}

export default EventWebsocket;
