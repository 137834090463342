import React from 'react';
import styles from './eventCode.less';
import HashCode from '../../graphics/hash.svg';
import { getEventWithCode } from '../../api/api';
import { navigate } from '@reach/router';

class EventCode extends React.PureComponent {
  state = {
    code: '',
    button: '',
    isLoading: false,
    id: Math.random().toString(36).substr(2, 9),
  };

  onChange = (evt) => {
    const { value } = evt.target;
    this.setState({
      code: value,
      missingCode: false,
      noEventFound: false,
    });
  };

  onSubmit = (evt) => {
    evt.preventDefault();
    const { code } = this.state;
    if (code.length === 0) {
      this.setState(
        {
          missingCode: true,
        },
        () => this.textFieldRef.focus()
      );
      return;
    }
    this.setState({
      isLoading: true,
      button: 'loading',
    });
    const minAnimationDuration = new Promise(function (resolve) {
      setTimeout(resolve, 1500);
    });
    Promise.all([minAnimationDuration, getEventWithCode(code)])
      .then(function (values) {
        const eventResponse = values[1];
        if (eventResponse.ok) {
          return eventResponse.json();
        }
      })
      .then((data) => {
        this.setState({
          isLoading: false,
          button: 'success',
        });
        navigate(`/event/${data.id}`);
      })
      .catch(() => {
        this.setState({
          noEventFound: true,
          isLoading: false,
          button: 'error',
        });
      });
  };

  render() {
    const { missingCode, isLoading, noEventFound } = this.state;
    const ariaAttr = {};
    if (missingCode) {
      ariaAttr['aria-invalid'] = true;
      ariaAttr['aria-describedby'] = 'missing-code-error';
    }
    return (
      <form className={styles.form} method="post" onSubmit={this.onSubmit}>
        <HashCode className={styles.hash} />
        <label htmlFor={this.state.id} className={styles.textFieldLabel}>
          <span className={styles.text}>Label</span>
          <input
            id={this.state.id}
            disabled={isLoading}
            {...ariaAttr}
            ref={(el) => (this.textFieldRef = el)}
            maxLength="4"
            className={styles.textField}
            type="tel"
            placeholder="Enter event code"
            onChange={this.onChange}
            autoComplete="off"
          />
        </label>
        {missingCode && (
          <p id="missing-code-error" className={styles.missingCode}>
            You need to enter a code first
          </p>
        )}
        {noEventFound && (
          <p className={styles.missingCode}>
            We could not find an event with that code. You sure you have the
            correct code?
          </p>
        )}
        <div>
          <button
            disabled={isLoading}
            className={
              isLoading ? styles.submitButtonLoading : styles.submitButton
            }
            type="button"
            onClick={this.onSubmit}
          >
            <span className={styles.label}>Join</span>
            <span className={styles.spinner} />
          </button>
        </div>
      </form>
    );
  }
}

export default EventCode;
