import { combineReducers } from 'redux';

import user from './session-reducer';
import events from './events-reducer';

const rootReducer = combineReducers({
  events,
  user,
});

export default rootReducer;
