import React from 'react';
import DefaultLayout from '../../layouts/default/defaultLayout';

const Faq = () => {
  return (
    <DefaultLayout pageTitle="FAQ - Questup">
      <h1>FAQ</h1>
      <h2>How do i do this?</h2>
      <p>
        You can achieve this by lettingskfldkfn skdf nsdk flfn,ds, n,fn d,fn
        ds,fn, dsn,dn f,sdnf ,sdnf, dmsnf,mdsnf,mdsnf,msdnf,s nf,mnsd,f
        nsd,fn,ds
      </p>
    </DefaultLayout>
  );
};

export default Faq;
