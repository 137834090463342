import React, { useState, useRef, useEffect } from 'react';
import styles from './questionPublicMode.less';
import defaultPropTypes from '../propTypes';
import { getVoteSuffix } from '../utils';
import VoteIcon from '../../../graphics/upvote.svg';
import StarIcon from '../../../graphics/star-filled.svg';
import PropTypes from 'prop-types';
import { getStoredVotes } from '../../../localStorage/localStorage';
import cx from 'classnames';
import PrimaryButton from '../../buttons/PrimaryButton';

// eslint-disable-next-line react/prop-types
const ActionBar = ({ votes, voted, isHighlighted, vote }) => {
  return (
    <div className={styles.actionBar}>
      <span className={styles.voteCount}>{`${votes}`}</span>
      {getVoteSuffix(votes)}
      <PrimaryButton
        skin="radiant"
        size="small"
        disabled={voted || isHighlighted}
        onClick={vote}
        icon={<VoteIcon />}
      >
        {voted && 'Voted'}
        {!voted && 'Upvote'}
      </PrimaryButton>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const ActionBarHighlighted = ({ votes, vote }) => {
  return (
    <div className={styles.actionBarHighlighted}>
      <PrimaryButton
        size="small"
        skin="radiant"
        icon={<StarIcon />}
        disabled
        onClick={vote}
      >
        Being highlighted
      </PrimaryButton>
      <span>
        <span className={styles.voteCount}>{`${votes}`}</span>
        {getVoteSuffix(votes)}
      </span>
    </div>
  );
};

const QuestionPublicMode = ({
  eventId,
  id,
  author,
  votes,
  question,
  onVote,
  active,
}) => {
  const [voted, setVoted] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    if (getStoredVotes(eventId).includes(id)) {
      setVoted(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('storage', onStorageUpdate, false);
    return () => {
      window.removeEventListener('storage', onStorageUpdate, false);
    };
  }, []);

  const onStorageUpdate = (evt) => {
    if (evt.key === 'voted') {
      if (getStoredVotes(eventId).includes(id)) {
        setVoted(true);
      } else {
        setVoted(false);
      }
    }
  };

  const vote = () => {
    setVoted(true);
    onVote(id);
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.containerHighlighted]: active,
      })}
      ref={containerRef}
      id={id}
    >
      <div className={styles.content}>
        <div className={styles.author}>
          <div className={styles.name}>{author}</div>
        </div>
        <div className={styles.question}>{question}</div>
      </div>
      {active && (
        <ActionBarHighlighted votes={votes} vote={vote} voted={voted} />
      )}
      {!active && (
        <ActionBar
          votes={votes}
          vote={vote}
          isHighlighted={active}
          voted={voted}
        />
      )}
    </div>
  );
};

QuestionPublicMode.propTypes = {
  ...defaultPropTypes,
  onVote: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  scrollIntoView: PropTypes.bool,
  active: PropTypes.bool,
};

export default QuestionPublicMode;
