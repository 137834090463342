import React from 'react';
import PropTypes from 'prop-types';
import OverlayGraphic from '../../graphics/wave-overlay.svg';
import styles from './presentationPage.less';

const EmptyScreen = ({ eventName, eventSlogan, eventPinCode }) => {
  return (
    <div className={styles.intro}>
      <div className={styles.content}>
        <h1 className={styles.introHeading}>{eventName}</h1>
        <p className="presentation-page__intro-desc">{eventSlogan}</p>
        <div className={styles.introInstructions}>
          <div>
            Go to <span className={styles.domainName}>questup.io</span>
          </div>
          <div className={styles.introPin}>PIN: #{eventPinCode}</div>
        </div>
        <OverlayGraphic className="presentation-page__wave-overlay" />
      </div>
    </div>
  );
};

EmptyScreen.propTypes = {
  eventName: PropTypes.string.isRequired,
  eventSlogan: PropTypes.string.isRequired,
  eventPinCode: PropTypes.string.isRequired,
};

export default EmptyScreen;
