import {
  FETCH_USER_DATA,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAILED,
} from './session-actions';

export const LOAD_STATUS = {
  NOT_REQUESTED: 'NOT_REQUESTED',
  REQUEST_IN_PROGRESS: 'REQUEST_IN_PROGRESS',
  REQUEST_SUCCEEDED: 'REQUEST_SUCCEEDED',
  REQUEST_FAILED: 'REQUEST_FAILED',
};

const initialState = {
  loadStatus: LOAD_STATUS.NOT_REQUESTED,
  httpStatusCode: undefined,
  user: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_DATA:
      return {
        ...state,
        loadStatus: LOAD_STATUS.REQUEST_IN_PROGRESS,
      };
    case FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        loadStatus: LOAD_STATUS.REQUEST_SUCCEEDED,
        user: {
          id: action.payload.id,
          email: action.payload.email,
          firstName: action.payload.first_name,
          lastName: action.payload.last_name,
          firstLogin: action.payload.firstLogin,
        },
      };
    case FETCH_USER_DATA_FAILED:
      return {
        ...state,
        loadStatus: LOAD_STATUS.REQUEST_FAILED,
        httpStatusCode: action.statusCode,
        user: undefined,
      };
    default:
      return state;
  }
};
