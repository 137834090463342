import { getUser } from '../api/api';
import { addEvents } from './events-actions';

export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAILED = 'FETCH_USER_DATA_FAILED';

export const fetchDataOnCurrentlyLoggedInUser = () => {
  return function (dispatch) {
    dispatch(fetchUserData());
    return getUser()
      .then((data) => {
        dispatch(addEvents(data.events));
        dispatch(fetchUserDataSuccess(data));
      })
      .catch((response) => {
        dispatch(fetchUserDataFailed(response.status));
      });
  };
};

export const fetchUserData = () => {
  return {
    type: FETCH_USER_DATA,
  };
};

export const fetchUserDataSuccess = (payload) => {
  return {
    type: FETCH_USER_DATA_SUCCESS,
    payload,
  };
};

export const fetchUserDataFailed = (statusCode) => {
  return {
    type: FETCH_USER_DATA_FAILED,
    statusCode: statusCode,
  };
};
