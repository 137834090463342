import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import schema from './form-validation';
import { login } from '../../api/api';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useDispatch } from 'react-redux';
import { fetchUserDataSuccess } from '../../redux/session-actions';
import Label from '../../components/formElements/label';
import Text from '../../components/formElements/text';
import Password from '../../components/formElements/password';
import { navigate } from '@reach/router';

const FormRevisited = () => {
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const dispatch = useDispatch();

  const submitForm = (formData, { setSubmitting }) => {
    login(formData)
      .then((response) => {
        dispatch(fetchUserDataSuccess(response));
        navigate('/admin', { replace: true });
      })
      .catch((response) => {
        if (response.status !== 500) {
          setInvalidLogin(true);
        } else {
          setLoginError(true);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={submitForm}
      validationSchema={schema}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        isSubmitting,
      }) => (
        <Form aria-invalid={Object.keys(errors).length > 0}>
          <div className="form__row">
            <Label htmlFor="field-email">Email</Label>
            <Text
              name="email"
              id="field-email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && errors.email}
              autoComplete="email"
            />
          </div>
          <div className="form__row">
            <Label htmlFor="field-password">Password</Label>
            <Password
              name="password"
              id="field-password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && errors.password}
              autoComplete="current-password"
            />
          </div>
          {invalidLogin && (
            <div
              aria-live="polite"
              className="form__row form__row--center form__error"
            >
              Login failed. Invalid username or password
            </div>
          )}
          {loginError && (
            <div
              aria-live="polite"
              className="form__row form__row--center form__error"
            >
              Login failed.
            </div>
          )}
          <PrimaryButton
            loading={isSubmitting}
            type="submit"
            disabled={isSubmitting}
            fluid
          >
            Log in
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  );
};

FormRevisited.propTypes = {};

export default FormRevisited;
