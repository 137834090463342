import React from 'react';

const LoadingSpinner = () => {
  return (
    <span className="spinnerContainer">
      <span className="spinner" />
    </span>
  );
};

export default LoadingSpinner;
