import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { register } from '../../api/api';
import { Link, navigate } from '@reach/router';
import validationSchema from './email-form-validation';
import PropTypes from 'prop-types';
import { fetchUserDataSuccess } from '../../redux/session-actions';
import { connect, useDispatch } from 'react-redux';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import Text from '../../components/formElements/text';
import Label from '../../components/formElements/label';

const EmailForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (formValues) => {
    setIsSubmitting(true);
    register(formValues)
      .then((response) => {
        dispatch(fetchUserDataSuccess(response));
        navigate('/admin', { replace: true });
      })
      .catch((response) => {
        if (response.status === 400) {
          response.json().then((errorPayload) => {
            if (errorPayload.errorCode === 'EMAIL.EXISTS') {
              setEmailExistsError(true);
            }
          });
        } else {
          setSubmitError(true);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="register-form">
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          password: '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, touched, values, handleChange, handleBlur }) => (
          <Form aria-invalid={Object.keys(errors).length > 0} className="form">
            <div className="row">
              <Label htmlFor="field-first-name">First name</Label>
              <Text
                id="field-first-name"
                name="first_name"
                value={values.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.first_name && errors.first_name}
                autoComplete="given-name"
                minLength={2}
                maxLength={50}
              />
            </div>
            <div className="row">
              <Label htmlFor="field-last-name">Last name</Label>
              <Text
                id="field-last-name"
                name="last_name"
                value={values.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.last_name && errors.last_name}
                autoComplete="family-name"
                minLength={2}
                maxLength={50}
              />
            </div>
            <div className="row">
              <Label htmlFor="field-email">Email</Label>
              <Text
                id="field-email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && errors.email}
                autoComplete="email"
                minLength={2}
                maxLength={100}
              />
            </div>
            <div className="row">
              <Label htmlFor="field-password">Password</Label>
              <Text
                id="field-password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && errors.password}
                autoComplete="new-password"
                minLength="6"
                maxLength="50"
              />
            </div>
            {emailExistsError && (
              <div aria-live="polite" className="form__row form__error">
                There already is an account with that email address. If you
                forgot your password you can{' '}
                <Link to="/forgotten-password">reset it</Link>.
              </div>
            )}
            {submitError && (
              <div aria-live="polite" className="form__row form__error">
                Sorry, but there seems to be a problem creating your account. We
                have been notified and will resolve the issue as quickly as
                possible. Please try again later.
              </div>
            )}
            <div className="form__row">
              <PrimaryButton
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
                fluid
              >
                Submit
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

EmailForm.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(fetchUserDataSuccess(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailForm);
