import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './root-reducer';

export default function configureStore() {
  //const middlewares = [thunkMiddleware];
  //const middlewareEnhancer = applyMiddleware(...middlewares)

  //const enhancers = [middlewareEnhancer];
  //const composedEnhancers = compose(...enhancers)
  // Grab the state from a global variable injected into the server-generated HTML
  // const preloadedState = window.__PRELOADED_STATE__;

  // Allow the passed state to be garbage-collected
  // delete window.__PRELOADED_STATE__;

  // Create Redux store with initial state
  const store = createStore(
    rootReducer,
    {},
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );

  // Tell react-snap how to save Redux state
  /*
  window.snapSaveState = () => ({
    __PRELOADED_STATE__: store.getState(),
  });
   */

  /*
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );
   */

  /*
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./root-reducer', () => store.replaceReducer(rootReducer))
  }
  */

  return store;
}
