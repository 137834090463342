import React from 'react';
import section from '../../homePage.less';
import styles from './hero.less';
import EventCodeForm from '../../eventCode';

const SectionHero = () => {
  return (
    <section className={styles.hero}>
      <div className={section.sectionContent}>
        <h1 className={styles.heading}>
          Make your event better with more engagement.
        </h1>
        <p className={styles.leadParagraph}>
          With Questup you can create an interactive Q&A session with your
          audience. In minutes.
        </p>
        <EventCodeForm />
      </div>
    </section>
  );
};
export default SectionHero;
