import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './presentationPage.less';
import screenfull from 'screenfull';
import { Circ, TimelineMax } from 'gsap';
import { throttle } from 'throttle-debounce';
import CloseIcon from '../../graphics/close-light.svg';
import CopyIcon from '../../graphics/copy-link.svg';
import HelpIcon from '../../graphics/help.svg';
import FullscreenIcon from '../../graphics/fullscreen-light.svg';
import copy from 'copy-text-to-clipboard';
import { animated, useTransition, config } from 'react-spring';

const contextMenuTimeline = new TimelineMax({
  paused: true,
  reversed: true,
});

const ContextMenu = ({ eventId }) => {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const contextMenuRef = useRef();
  const mouseMoveEventListenerRef = useRef();
  const timeoutRef = useRef();
  const [showCopyConfirmation, setShowCopyCOnfirmation] = useState(false);

  const copyConfirmationTransitions = useTransition(
    showCopyConfirmation,
    null,
    {
      from: { top: '20px' },
      enter: { top: '-60px' },
      leave: { top: '20px' },
      config: config.wobbly,
    }
  );

  useEffect(() => {
    const isInIframe = window.self !== window.top;
    if (isInIframe) {
      return;
    }
    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        setFullscreen(screenfull.isFullscreen);
      });
    }
    contextMenuTimeline.to(
      contextMenuRef.current,
      0.3,
      {
        y: `-${contextMenuRef.current.offsetHeight}px`,
        ease: Circ.easeOut,
        opacity: 1,
        onComplete: () => {
          setShowContextMenu(false);
        },
      },
      0
    );
    mouseMoveEventListenerRef.current = throttle(200, handleMouseMove);
    const timeout = setTimeout(() => {
      document.addEventListener(
        'mousemove',
        mouseMoveEventListenerRef.current,
        false
      );
    }, 1500);
    return () => {
      document.removeEventListener(
        'mousemove',
        mouseMoveEventListenerRef.current
      );
      clearTimeout(timeout);
    };
  }, []);

  const copyToClipboard = () => {
    copy(`${window.location.origin}/event/${eventId}/presentation`);
    setShowCopyCOnfirmation(true);
    setTimeout(() => {
      setShowCopyCOnfirmation(false);
    }, 2000);
  };

  const handleMouseMove = (evt) => {
    if (!showContextMenu) {
      setShowContextMenu(true);
      contextMenuTimeline.play();
      document.body.style.cursor = 'auto';
    }
    clearTimeout(timeoutRef.current);
    const contextMenuRect = contextMenuRef.current.getBoundingClientRect();
    const isMouseYPosWithinContextMenu =
      contextMenuRect.top < evt.clientY && contextMenuRect.bottom > evt.clientY;
    const isMouseXPosWithinContextMenu =
      contextMenuRect.left < evt.clientX && contextMenuRect.right > evt.clientX;
    if (!isMouseXPosWithinContextMenu || !isMouseYPosWithinContextMenu) {
      timeoutRef.current = setTimeout(dismissContextMenu, 1500);
    } else {
      clearTimeout(timeoutRef.current);
    }
  };

  const dismissContextMenu = () => {
    contextMenuTimeline.reverse();
    document.body.style.cursor = 'none';
  };

  const toggleFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  };

  return (
    <div className={styles.contextMenu} ref={contextMenuRef}>
      <div className={styles.contextMenuContent}>
        <button onClick={toggleFullscreen} className={styles.contextMenuButton}>
          {fullscreen ? <CloseIcon /> : <FullscreenIcon />}
          <div>{fullscreen ? 'Exit fullscreen' : 'Fullscreen'}</div>
        </button>
        <button onClick={copyToClipboard} className={styles.contextMenuButton}>
          <CopyIcon />
          <div>Copy link</div>
        </button>
        <button onClick={toggleFullscreen} className={styles.contextMenuButton}>
          <HelpIcon />
          <div>Help</div>
        </button>
      </div>
      {copyConfirmationTransitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              className={styles.copyConfirmation}
              style={props}
            >
              <div>Copied!</div>
            </animated.div>
          )
      )}
    </div>
  );
};

ContextMenu.propTypes = {
  eventId: PropTypes.string.isRequired,
};

export default ContextMenu;
