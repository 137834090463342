import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './animatedList.less';
import throttle from 'lodash/throttle';
import { animated, useTransition } from 'react-spring';

let resizeEventThrottle;
const spaceBetweenQuestions = 15;

const AnimatedList = ({ questions, renderListItem }) => {
  const [questionsWithHeight, setQuestionsWithHeight] = useState([]);
  const containerRef = useRef(null);
  const questionsRef = useRef();
  questionsRef.current = questions;

  let height = 0;
  const transitions = useTransition(
    questionsWithHeight.map((data) => ({
      ...data,
      y: (height = height + data.height + spaceBetweenQuestions) - data.height,
    })),
    (d) => {
      return d.id;
    },
    {
      from: { height: 0, opacity: 0 },
      leave: { height: 0, opacity: 0 },
      enter: ({ y, height }) => ({ y, height, opacity: 1 }),
      update: ({ y, height }) => ({ y, height }),
    }
  );

  useEffect(() => {
    resizeEventThrottle = throttle(() => {
      calculateHeightOfElements();
    }, 250);
    window.addEventListener('resize', resizeEventThrottle);
    return () => {
      window.removeEventListener('resize', resizeEventThrottle);
    };
  }, [questions]);

  useLayoutEffect(() => {
    calculateHeightOfElements();
  }, [questions]);

  const calculateHeightOfElements = () => {
    const children = Array.from(containerRef.current.children);
    if (children.length !== questions.length) {
      console.log(`dom children`, containerRef.current.children);
      console.log(`converted to array`, children);
      console.log(`questions`, questions);
    }
    const questionsWithCalculatedHeight = questions.map((q, index) => ({
      ...q,
      height: children[index].getBoundingClientRect().height,
    }));
    setQuestionsWithHeight(questionsWithCalculatedHeight);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.hiddenWrapper} ref={containerRef}>
        {questions.map((question) => {
          return renderListItem({ ...question, id: `hidden-${question.id}` });
        })}
      </div>
      <div className="list" style={{ height }}>
        {transitions.map(({ item, props: { y, ...rest }, key }, index) => (
          <animated.div
            key={key}
            className={styles.card}
            style={{
              zIndex: questions.length - index,
              transform: y.interpolate((y) => `translate3d(0,${y}px,0)`),
              ...rest,
            }}
          >
            {renderListItem(item)}
          </animated.div>
        ))}
      </div>
    </div>
  );
};

AnimatedList.propTypes = {
  questions: PropTypes.array,
  renderListItem: PropTypes.func.isRequired,
};

export default AnimatedList;
