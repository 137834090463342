import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { QuestionPresentationMode } from '../question';
import styles from './presentationPage.less';

class PromotedQuestion extends Component {
  // TODO: animering av bytte av spm...
  //
  render() {
    let { author, question, votes } = this.props;
    return (
      <React.Fragment>
        <div
          className={styles.highlightedQuestion}
          ref={(el) => (this.questionRef = el)}
        >
          <QuestionPresentationMode
            author={author}
            question={question}
            votes={votes}
            isLive={true}
          />
        </div>
      </React.Fragment>
    );
  }
}

PromotedQuestion.propTypes = {
  author: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  votes: PropTypes.number.isRequired,
};

export default PromotedQuestion;
