import React from 'react';
import LoginFormHook from './formRevisited';
import styles from './login.less';
import {
  FacebookButton,
  GoogleButton,
} from '../../components/buttonLink/buttonLink';
import PageCard from '../../components/pageCard/pageCard';
import { Link } from '@reach/router';
import DefaultLayout from '../../layouts/default/defaultLayout';

const Login = () => {
  return (
    <DefaultLayout pageTitle="Login - Questup">
      <PageCard heading="Login">
        <div className={styles.buttons}>
          <FacebookButton url={`${REST_API_HOST}/auth/facebook`}>
            Login using Facebook
          </FacebookButton>
          <GoogleButton url={`${REST_API_HOST}/auth/google`}>
            Login using Google
          </GoogleButton>
        </div>
        <p className={styles.emailOptionDesc}>
          Or login using your email address and password
        </p>
        <div className={styles.emailLoginForm}>
          <LoginFormHook />
        </div>
        <div className={styles.helpLinks}>
          <Link className={styles.helpLink} to="/forgotten-password">
            Forgot password
          </Link>
          <Link className={styles.helpLink} to="/register">
            Register
          </Link>
        </div>
      </PageCard>
    </DefaultLayout>
  );
};

export default Login;
