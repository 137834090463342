import { ADD_EVENTS, UPDATE_EVENT_NAME, DELETE_EVENT } from './events-actions';
import { FETCH_USER_DATA_SUCCESS } from './session-actions';

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_EVENTS:
      return [...state, ...action.payload];
    case UPDATE_EVENT_NAME:
      return state.map((event) => {
        if (event.id === action.eventId) {
          return {
            ...event,
            name: action.name,
          };
        }
        return event;
      });
    case DELETE_EVENT:
      return state.filter((event) => event.id !== action.eventId);
    case FETCH_USER_DATA_SUCCESS:
      return action.payload.events;
    default:
      return state;
  }
};
