import React from 'react';
import PropTypes from 'prop-types';
import styles from './pageCard.less';

const PageCard = ({ heading, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.headingWrapper}>
        <h1 className={`heading-section ${styles.heading}`}>{heading}</h1>
      </div>
      {children}
    </div>
  );
};

PageCard.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default PageCard;
