/* global process */
import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './app.js';
import './style/app.less';
import configureStore from './redux/configure-store';
import { fetchDataOnCurrentlyLoggedInUser } from './redux/session-actions';
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://5cb20b0ab49848da855534beb4a5177a@sentry.io/1428467',
  });
}

document.getElementsByTagName('body')[0].classList.remove('noscript');

const store = configureStore();
store.dispatch(fetchDataOnCurrentlyLoggedInUser());

const rootElement = document.getElementById('app');
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
}
