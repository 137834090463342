import React from 'react';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import styles from './pricing.less';
import Features, { Feature } from './Features';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/selectors/selectors';
import Price from './Price';
import ProNotificationForm from './ProNotificationForm';
import DefaultLayout from '../../layouts/default/defaultLayout';

const Pricing = () => {
  const user = useSelector(getUser);
  return (
    <DefaultLayout pageTitle="Pricing - Questup">
      <h1>Pricing</h1>
      <section className={styles.priceGroup}>
        <div className={styles.descriptionSectionFree}>
          <div>
            <h2 className={styles.descriptionHeading}>Free version</h2>
            <p>
              A suitable solution for classrooms, conference, team meetings and
              social events.
            </p>
            {!user && (
              <p className={styles.signupButton}>
                <PrimaryButton skin="radiant" fluid url="/register">
                  Sign up
                </PrimaryButton>
              </p>
            )}
          </div>
        </div>
        <div className={styles.featuresSection}>
          <Price label="Free version" price="0" />
          <Features>
            <Feature>Interactive Q&As</Feature>
            <Feature>Unlimited participants</Feature>
            <Feature>Unlimited events</Feature>
            <Feature>Moderate questions</Feature>
          </Features>
        </div>
      </section>
      <section className={styles.priceGroup}>
        <div className={styles.descriptionSectionPro}>
          <div>
            <h2 className={styles.descriptionHeading}>Pro version</h2>
            <p>We’re working on a pro version with pro features.</p>
            <ProNotificationForm userEmail={user?.email} />
          </div>
        </div>
        <div className={styles.featuresSection}>
          <Price label="Pro version" price="🤷‍♀️" />
          <p className={styles.additional}>
            Everything in the free version. Plus features like:
          </p>
          <Features>
            <Feature>Live polls</Feature>
            <Feature>Polls</Feature>
            <Feature>Word clouds</Feature>
            <Feature>Custom themes</Feature>
            <Feature>Custom logo</Feature>
            <Feature>Surveys</Feature>
            <Feature>...And more</Feature>
          </Features>
        </div>
      </section>
    </DefaultLayout>
  );
};

export default Pricing;
