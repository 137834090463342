import React from 'react';
import DefaultLayout from '../../layouts/default/defaultLayout';

const UseCases = () => {
  return (
    <DefaultLayout pageTitle="Use cases - Questup">
      <h1>Use cases</h1>
    </DefaultLayout>
  );
};

export default UseCases;
