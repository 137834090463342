const localStorageKeys = {
  QUESTIONS: 'questions',
  VOTED: 'voted',
  AUTHOR: 'author',
};

const getStoredEventsVotesMap = () => {
  const eventsAsJson = localStorage.getItem(localStorageKeys.VOTED);
  return !eventsAsJson ? {} : JSON.parse(eventsAsJson);
};

const getStoredEventsQuestionsMap = () => {
  const eventsAsJson = localStorage.getItem(localStorageKeys.QUESTIONS);
  return !eventsAsJson ? {} : JSON.parse(eventsAsJson);
};

export const getStoredQuestions = (eventId) => {
  const events = getStoredEventsQuestionsMap();
  return events[`${eventId}`] || [];
};

export const getStoredVotes = (eventId) => {
  const storedEvents = getStoredEventsVotesMap();
  return storedEvents[eventId] || [];
};

export const storeVote = (eventId, questionId) => {
  const storedEvents = getStoredEventsVotesMap();
  const questionsVotedFor = getStoredVotes(eventId);
  if (!questionsVotedFor.some((id) => id === questionId)) {
    // questionId not previously registered
    const voted = {
      ...storedEvents,
      [eventId]: [...questionsVotedFor, questionId],
    };
    localStorage.setItem(localStorageKeys.VOTED, JSON.stringify(voted));
  }
};

export const storeQuestionAuthorForEvent = (author, eventId) => {
  const jsonValue = localStorage.getItem(localStorageKeys.AUTHOR);
  let storedAuthor = {};
  if (jsonValue) {
    storedAuthor = JSON.parse(jsonValue);
  }
  const data = {
    ...storedAuthor,
    [`${eventId}`]: author,
  };
  localStorage.setItem(localStorageKeys.AUTHOR, JSON.stringify(data));
};

export const getStoredAuthorForEvent = (eventId) => {
  const jsonData = localStorage.getItem(localStorageKeys.AUTHOR);
  if (!jsonData) {
    return undefined;
  }
  const authorMap = JSON.parse(jsonData);
  return authorMap[eventId];
};
