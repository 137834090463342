import React from 'react';
import styles from './buttonLink.less';
import FacebookIcon from '../../graphics/facebook.svg';
import GoogleIcon from '../../graphics/google.svg';
import LetterIcon from '../../graphics/letter.svg';

// eslint-disable-next-line
const ButtonLink = ({ url, children, buttonRef, ...otherProps }) => (
  <a href={url} {...otherProps}>
    {children}
  </a>
);

// eslint-disable-next-line
export const FacebookButton = ({ url, children }) => (
  <ButtonLink className={styles.facebook} url={url}>
    <FacebookIcon className={styles.icon} />
    {children}
  </ButtonLink>
);

// eslint-disable-next-line
export const GoogleButton = ({ url, children }) => (
  <ButtonLink className={styles.google} url={url}>
    <GoogleIcon className={styles.icon} />
    {children}
  </ButtonLink>
);

// eslint-disable-next-line
export const EmailButton = ({ children, ...otherProps }) => (
  <ButtonLink className={styles.email} {...otherProps}>
    <LetterIcon className={styles.icon} />
    {children}
  </ButtonLink>
);

// eslint-disable-next-line
export const FacebookMiniButton = ({ url, children, ...otherProps }) => (
  <ButtonLink className={styles.facebookMini} url={url} {...otherProps}>
    {children}
  </ButtonLink>
);

// eslint-disable-next-line
export const TwitterMiniButton = ({ url, children, ...otherProps }) => (
  <ButtonLink className={styles.twitterMini} url={url} {...otherProps}>
    {children}
  </ButtonLink>
);
