import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import defaultPropTypes from '../propTypes';
import styles from './questionModerateMode.less';
import { getVoteSuffix } from '../utils';
import StarIcon from '../../../graphics/star.svg';
import StarIconFilled from '../../../graphics/star-filled.svg';
import DeleteIcon from '../../../graphics/delete.svg';
import ConfirmationDialog from '../../confirmationDialog/confirmationDialog';
import IconButton from '../../buttons/IconButton';
import PrimaryButton from '../../buttons/PrimaryButton';

const QuestionModerateMode = ({
  id,
  author,
  votes,
  question,
  onHighlightQuestion,
  isHighlighted,
  isArchived,
  onDelete,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const deleteButtonRef = useRef();
  return (
    <div
      className={
        isHighlighted ? styles['container--highlighted'] : styles.container
      }
    >
      <div className={styles.content}>
        <div className={styles.author}>
          <div className={styles.name}>{author}</div>
        </div>
        <div className={styles.question}>{question}</div>
      </div>
      {isHighlighted && (
        <div className={styles['actionBar--highlighted']}>
          <PrimaryButton
            size="small"
            skin="radiant"
            icon={<StarIconFilled />}
            disabled
          >
            Being highlighted
          </PrimaryButton>
        </div>
      )}
      {!isHighlighted && !isArchived && (
        <div className={styles.actionBar}>
          <div>
            <span className={styles.voteCount}>{votes}</span>{' '}
            {getVoteSuffix(votes)}
          </div>
          <div className={styles.actionBarButtonContainer}>
            <IconButton
              skin="dangerFaded"
              ref={deleteButtonRef}
              onClick={() => {
                setShowDeleteConfirmation(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <PrimaryButton
              size="small"
              skin="radiant"
              icon={<StarIcon />}
              onClick={onHighlightQuestion}
            >
              Highlight question
            </PrimaryButton>
            {showDeleteConfirmation && (
              <ConfirmationDialog
                onCancel={() => setShowDeleteConfirmation(false)}
                text="Are you sure you want to delete the question?"
                elementRef={deleteButtonRef.current}
                onConfirm={() => {
                  setShowDeleteConfirmation(false);
                  onDelete(id);
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

QuestionModerateMode.propTypes = {
  ...defaultPropTypes,
  onHighlightQuestion: PropTypes.func.isRequired,
  isHighlighted: PropTypes.bool,
  isArchived: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
};

export default QuestionModerateMode;
