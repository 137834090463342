import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './appNavBar.less';
import Logo from '../../graphics/logo.svg';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import styles from './appNavBar.less';
import { LOAD_STATUS } from '../../redux/session-reducer';
import NavLink from './NavLink';
import PrimaryButton from '../buttons/PrimaryButton';
import { animated, useTransition } from 'react-spring';
import HamburgerButton from './burger';

export function AppNavBar(props) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const menuTransition = useTransition(showMobileMenu, null, {
    from: {
      transform: 'translate3d(0,-110%,0)',
    },
    enter: {
      transform: 'translate3d(0, 0%,0)',
    },
    leave: {
      transform: 'translate3d(0,-110%,0)',
    },
  });
  const { userLoadStatus } = props;
  const isAuthorized = userLoadStatus === LOAD_STATUS.REQUEST_SUCCEEDED;
  return (
    <>
      <header className={styles.appNavBar}>
        <section className={styles.contentContainer}>
          <Link to="/" className={styles.logoLink} aria-label="Home">
            <Logo className={styles.logo} />
          </Link>
          <div className={styles.navigation}>
            <nav className={styles.navLinks}>
              <NavLink
                className={styles.link}
                activeClassName={styles.linkActive}
                to="/"
              >
                Home
              </NavLink>
              <NavLink
                className={styles.link}
                activeClassName={styles.linkActive}
                to="/pricing"
              >
                Pricing
              </NavLink>
              {!isAuthorized && (
                <React.Fragment>
                  <NavLink
                    to="/login"
                    className={styles.link}
                    activeClassName={styles.linkActive}
                  >
                    Login
                  </NavLink>
                  <Link to="/register" className={styles.getStarted}>
                    Get started for free
                  </Link>
                </React.Fragment>
              )}
            </nav>
            {isAuthorized && (
              <Link className={styles.profileLink} to="/admin">
                Your events
              </Link>
            )}
            <HamburgerButton
              isOpen={showMobileMenu}
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            />
          </div>
        </section>
        {menuTransition.map(
          ({ item, props, key }) =>
            item && (
              <animated.div
                key={key}
                className={styles.mobileMenu}
                style={props}
              >
                <nav>
                  <div>
                    <NavLink
                      className={styles.link}
                      activeClassName={styles.linkActive}
                      to="/"
                    >
                      Home
                    </NavLink>
                  </div>
                  <div>
                    <NavLink
                      className={styles.link}
                      activeClassName={styles.linkActive}
                      to="/pricing"
                    >
                      Pricing
                    </NavLink>
                  </div>
                  {isAuthorized && (
                    <a
                      className={styles.link}
                      href={`${REST_API_HOST}/user/logout`}
                    >
                      Logout
                    </a>
                  )}
                  {!isAuthorized && (
                    <>
                      <div>
                        <NavLink
                          className={styles.link}
                          activeClassName={styles.linkActive}
                          to="/login"
                        >
                          Login
                        </NavLink>
                      </div>
                      <div className={styles.getStartedContainer}>
                        <PrimaryButton skin="transparent" fluid url="/register">
                          Get started
                        </PrimaryButton>
                      </div>
                    </>
                  )}
                </nav>
              </animated.div>
            )
        )}
      </header>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userLoadStatus: state.user.loadStatus,
  };
};

AppNavBar.propTypes = {
  userLoadStatus: PropTypes.oneOf([
    LOAD_STATUS.NOT_REQUESTED,
    LOAD_STATUS.REQUEST_IN_PROGRESS,
    LOAD_STATUS.REQUEST_FAILED,
    LOAD_STATUS.REQUEST_SUCCEEDED,
  ]).isRequired,
};

export default connect(mapStateToProps)(AppNavBar);
