import * as yup from 'yup';
//import { isEmailTaken } from '../../api/api';

const schema = yup.object().shape({
  first_name: yup.string().trim().required().min(2).max(30).label('First name'),
  last_name: yup.string().trim().required().min(2).max(30).label('Last name'),
  email: yup
    .string()
    .trim()
    .required()
    .email()
    .max(50)
    /* TODO resolve this
    .test(
      'unique-email', // this is used internally by yup
      'The email is already registered', //validation message
      value => isEmailTaken(value).then(response => !response)
    )

     */
    .label('Email'),
  password: yup.string().trim().required().min(6).max(50).label('Password'),
});

export default schema;
