import React from 'react';
import section from '../../homePage.less';
import styles from './try.less';
import { Link } from '@reach/router';

const Try = () => {
  return (
    <section className={styles.try}>
      <div className={section.sectionContent}>
        <section>
          <h2 className={styles.heading}>Test Questup for free</h2>
          <Link to="/register" className={styles.link}>
            Get started
          </Link>
          <p className={styles.desc}>No credit card required</p>
        </section>
      </div>
    </section>
  );
};
export default Try;
