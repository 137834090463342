import React, { useRef } from 'react';
import styles from './burger.less';
import { useSpring, animated, useChain } from 'react-spring';

// eslint-disable-next-line react/prop-types
const Burger = ({ isOpen, onClick }) => {
  const topRef = useRef();
  const topAnimation = useSpring({
    ref: topRef,
    transform: isOpen
      ? 'translate3d(0,6px,0) rotate(405deg)'
      : 'translate3d(0,0px,0) rotate(0deg)',
  });
  const middleRef = useRef();
  const middleAnimation = useSpring({
    ref: middleRef,
    opacity: isOpen ? 0 : 1,
    config: {
      duration: 0.3,
    },
  });
  const bottomRef = useRef();
  const bottomAnimation = useSpring({
    ref: bottomRef,
    transform: isOpen
      ? 'translate3d(0,-6px,0) rotate(-405deg)'
      : 'translate3d(0,0px,0) rotate(0deg)',
  });
  useChain(
    isOpen ? [middleRef, topRef, bottomRef] : [topRef, bottomRef, middleRef],
    isOpen ? [0, 0.06, 0.06] : [0, 0, 0.3]
  );
  return (
    <button className={styles.burger} onClick={() => onClick()}>
      <>
        <animated.span className={styles.top} style={topAnimation} />
        <animated.span className={styles.middle} style={middleAnimation} />
        <animated.span className={styles.bottom} style={bottomAnimation} />
      </>
    </button>
  );
};

Burger.propTypes = {};

export default Burger;
