export const ADD_EVENTS = 'ADD_EVENTS';
export const UPDATE_EVENT_NAME = 'UPDATE_EVENT_NAME';
export const DELETE_EVENT = 'DELETE_EVENT';

export const addEvents = (events) => {
  return {
    type: ADD_EVENTS,
    payload: events,
  };
};

export const updateEventName = (name, eventId) => {
  return {
    type: UPDATE_EVENT_NAME,
    name,
    eventId,
  };
};

export const deleteEvent = (eventId) => {
  return {
    type: DELETE_EVENT,
    eventId,
  };
};
