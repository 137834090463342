import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './confirmationDIalog.less';
import { Expo, TweenMax } from 'gsap';
import ReactDOM from 'react-dom';

const ConfirmationDialog = ({
  elementRef,
  onCancel,
  onConfirm,
  text,
  cancelLabel,
  confirmLabel,
}) => {
  const deleteConfirmation = useRef(null);
  const deleteConfirmationCancel = useRef(null);
  const arrowRef = useRef(null);
  useEffect(() => {
    showConfirmation();
  }, []);

  const onConfirmClick = () => {
    onConfirm();
  };

  const showConfirmation = () => {
    // Finn knappens posisjon
    const targetElement = elementRef.getBoundingClientRect();
    const confirmDialog = deleteConfirmation.current.getBoundingClientRect();
    deleteConfirmation.current.style.top = `${
      targetElement.top - confirmDialog.height + window.pageYOffset + 20
    }px`;
    const centerPoint = targetElement.left + targetElement.width / 2;
    if (centerPoint < window.innerWidth / 2) {
      // centerpoint is mostly to the left
      const leftPos = centerPoint - confirmDialog.width / 2;
      console.log(`left pos`, leftPos);
      // is leftpos off screen?
      if (leftPos < 15) {
        deleteConfirmation.current.style.left = `15px`;
        arrowRef.current.style.left = `${
          centerPoint - 15 - arrowRef.current.getBoundingClientRect().width / 2
        }px`;
      } else {
        deleteConfirmation.current.style.left = `${leftPos}px`;
        arrowRef.current.style.left = `${
          confirmDialog.width / 2 -
          arrowRef.current.getBoundingClientRect().width / 2
        }px`;
      }
    } else {
      // center point is mostly to the right
      const leftPos = centerPoint - confirmDialog.width / 2;
      // is rightpos off screen?
      const availableSpaceToTheRight =
        window.innerWidth - 15 - (leftPos + confirmDialog.width);
      console.log(availableSpaceToTheRight);
      if (availableSpaceToTheRight < 15) {
        //its off screen to the right
        console.log(`leftPos`, leftPos);
        const adjustedOffset = Math.abs(leftPos + availableSpaceToTheRight);
        console.log(`adjusted`, adjustedOffset);
        deleteConfirmation.current.style.left = `${Math.abs(
          leftPos + availableSpaceToTheRight
        )}px`;
        arrowRef.current.style.left = `${centerPoint - adjustedOffset}px`;
      } else {
        deleteConfirmation.current.style.left = `${leftPos}px`;
        arrowRef.current.style.left = `${
          confirmDialog.width / 2 -
          arrowRef.current.getBoundingClientRect().width / 2
        }px`;
      }
    }
    TweenMax.to(deleteConfirmation.current, 0.4, {
      y: -40,
      opacity: 1,
      ease: Expo.easeOut,
      onComplete: () => deleteConfirmationCancel.current.focus(),
    });
  };

  const dismissConfirmation = () => {
    elementRef.focus();
    TweenMax.to(deleteConfirmation.current, 0.3, {
      opacity: 0,
      y: -10,
      ease: Expo.easeOut,
      onComplete: onCancel,
    });
  };
  return ReactDOM.createPortal(
    <div className={styles.deleteConfirmation} ref={deleteConfirmation}>
      <p>{text}</p>
      <div className={styles.confirmationButtons}>
        <button
          onClick={dismissConfirmation}
          type="button"
          ref={deleteConfirmationCancel}
          className={styles.cancel}
        >
          {cancelLabel}
        </button>
        <button
          onClick={onConfirmClick}
          type="button"
          className={styles.confirm}
        >
          {confirmLabel}
        </button>
      </div>
      <div className={styles.arrow} ref={arrowRef} />
    </div>,
    document.getElementsByTagName('body')[0]
  );
};

ConfirmationDialog.propTypes = {
  elementRef: PropTypes.instanceOf(Element).isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
};

ConfirmationDialog.defaultProps = {
  cancelLabel: 'Cancel',
  confirmLabel: 'Yes',
};

export default ConfirmationDialog;
