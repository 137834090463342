import React from 'react';
import PropTypes from 'prop-types';
import styles from './buttons.less';
import cx from 'classnames';
import { Link } from '@reach/router';
import LoadingSpinner from '../loadingAnimations/loadingSpinner';
import { useSpring, animated, config } from 'react-spring';

const PrimaryButton = React.forwardRef(
  (
    { type, skin, fluid, size, icon, loading, children, url, ...otherProps },
    ref
  ) => {
    const contentProps = useSpring({
      transform: loading ? 'translateY(-48px)' : 'translateY(0px)',
      from: {
        transform: 'translateY(0px)',
      },
      config: config.stiff,
    });
    const spinnerProps = useSpring({
      transform: loading ? 'translateY(0)' : 'translateY(48px)',
      from: {
        transform: 'translateY(48px)',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      config: config.stiff,
    });

    const className = cx(styles.base, {
      [styles.skinDark]: skin === 'dark',
      [styles.skinRadiant]: skin === 'radiant',
      [styles.skinLight]: skin === 'light',
      [styles.skinDanger]: skin === 'danger',
      [styles.skinTransparent]: skin === 'transparent',
      [styles.widthFixed]: !fluid,
      [styles.widthFluid]: fluid,
      [styles.widthFluid]: fluid,
      [styles.heightMedium]: size === 'medium',
      [styles.heightSmall]: size === 'small',
      [styles.withIcon]: icon,
    });
    const body = (
      <span className={styles.content}>
        {icon && React.cloneElement(icon, { className: styles.iconWithText })}
        {children}
      </span>
    );
    if (!url) {
      return (
        <button type={type} className={className} ref={ref} {...otherProps}>
          <animated.span
            style={contentProps}
            className={styles.animatedContainer}
          >
            {body}
          </animated.span>
          <animated.span
            style={spinnerProps}
            className={styles.animatedContainer}
          >
            <LoadingSpinner />
          </animated.span>
        </button>
      );
    }
    if (url.startsWith('http')) {
      return (
        <a
          href={url}
          className={className}
          ref={ref}
          rel="noopener noreferrer"
          target="_blank"
          {...otherProps}
        >
          {body}
        </a>
      );
    }
    if (url.startsWith('mailto')) {
      return (
        <a className={className} ref={ref} href={url} {...otherProps}>
          {body}
        </a>
      );
    }
    return (
      <Link className={className} ref={ref} to={url} {...otherProps}>
        {body}
      </Link>
    );
  }
);

PrimaryButton.propTypes = {
  type: PropTypes.string,
  skin: PropTypes.oneOf(['dark', 'radiant', 'light', 'danger', 'transparent']),
  fluid: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  icon: PropTypes.element,
  loading: PropTypes.bool,
  /**
   * Set the url prop to render the button as an anchor tag
   */
  url: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PrimaryButton.defaultProps = {
  type: 'button',
  skin: 'dark',
  fluid: false,
  size: 'medium',
};

export default PrimaryButton;
