import moment from 'moment';

export const sortByVotes = (questions, descending = true) => {
  const temp = [...questions];
  temp.sort((a, b) => {
    if (a.votes > b.votes) {
      return descending ? -1 : 1;
    }
    if (a.votes < b.votes) {
      return descending ? 1 : -1;
    }
    return sortByCreatedDate(a, b, false);
  });
  return temp;
};

const sortByCreatedDate = (questionA, questionB, descending = true) => {
  if (moment(questionA.created).isAfter(moment(questionB.created))) {
    return descending ? -1 : 1;
  }
  if (moment(questionA.created).isBefore(moment(questionB.created))) {
    return descending ? 1 : -1;
  }
  return 0;
};

export const sortByCreated = (questions, descending = true) => {
  const temp = [...questions];
  temp.sort((a, b) => sortByCreatedDate(a, b, descending));
  return temp;
};

export const sortByUpdated = (questions, descending = true) => {
  return questions.sort((a, b) => {
    if (moment(a.updated).isAfter(moment(b.updated))) {
      return descending ? -1 : 1;
    }
    if (moment(a.updated).isBefore(moment(b.updated))) {
      return descending ? 1 : -1;
    }
    return 0;
  });
};
