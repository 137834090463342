import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { Form, Formik } from 'formik';
import Label from '../../components/formElements/label';
import Text from '../../components/formElements/text';
import styles from './pricing.less';
import * as yup from 'yup';
import { subscribeToProReleaseNotification } from '../../api/api';

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
});

const timeOut = (t) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(`Completed in ${t}`);
    }, t);
  });
};

const ProNotificationForm = ({ userEmail = '' }) => {
  const [showForm, setShowForm] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [subscribeError, setSubscribeError] = useState(false);
  const submitForm = (formData, { setSubmitting }) => {
    Promise.all([
      timeOut(1500),
      subscribeToProReleaseNotification(formData.email),
    ])
      .then((values) => {
        console.log(values);
        setSubmitting(false);
        setSubscribed(true);
      })
      .catch(() => {
        setSubmitting(false);
        setSubscribeError(true);
      });
  };

  if (subscribed) {
    return (
      <>
        <h1>You&apos;re subscribed! 🎉</h1>
        <p className={styles.confirmationText}>
          We&apos;ll let you know when our Pro version is released. While
          you&apos;re waiting we hope you will continue to use our free version.
        </p>
      </>
    );
  }
  if (showForm) {
    return (
      <Formik
        initialValues={{ email: userEmail }}
        onSubmit={submitForm}
        validationSchema={validationSchema}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form
            aria-invalid={Object.keys(errors).length > 0}
            className={styles.notifyMeForm}
          >
            <Label htmlFor="field-email">Email</Label>
            <div className={styles.emailField}>
              <Text
                name="email"
                id="field-email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && errors.email}
                autoComplete="email"
              />
            </div>
            {subscribeError && (
              <div className={styles.subscribeError}>
                Oh no! Seems like we made a mistake. This was unfortunate, but
                we&apos;re looking into what went wrong. In the meantime you
                could follow us on twitter to see when we release new features
              </div>
            )}
            <PrimaryButton loading={isSubmitting} type="submit">
              Submit
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    );
  }
  return (
    <PrimaryButton onClick={() => setShowForm(true)}>
      Notify me when Pro is launched
    </PrimaryButton>
  );
};

ProNotificationForm.propTypes = {
  userEmail: PropTypes.string,
};

export default ProNotificationForm;
