import React from 'react';
import PropTypes from 'prop-types';
import styles from './buttons.less';
import cx from 'classnames';

const IconButton = React.forwardRef(
  ({ skin, url, children, ...otherProps }, ref) => {
    const className = cx(styles.base, styles.iconOnly, styles.heightSmall, {
      [styles.skinDark]: skin === 'dark',
      [styles.skinRadiant]: skin === 'radiant',
      [styles.skinLight]: skin === 'light',
      [styles.skinDanger]: skin === 'danger',
      [styles.skinDangerFaded]: skin === 'dangerFaded',
      [styles.skinTransparent]: skin === 'transparent',
    });
    if (!url) {
      return (
        <button className={className} {...otherProps} ref={ref}>
          {React.cloneElement(children, { className: styles.icon })}
        </button>
      );
    }
    if (url.startsWith('http')) {
      return (
        <a
          href={url}
          className={className}
          ref={ref}
          rel="noopener noreferrer"
          target="_blank"
          {...otherProps}
        >
          {React.cloneElement(children, { className: styles.icon })}
        </a>
      );
    }
  }
);

IconButton.propTypes = {
  skin: PropTypes.oneOf([
    'dark',
    'radiant',
    'light',
    'danger',
    'dangerFaded',
    'transparent',
  ]),
  url: PropTypes.string,
  children: PropTypes.element.isRequired,
};

IconButton.defaultProps = {
  skin: 'dark',
};

export default IconButton;
