import React from 'react';
import styles from './register.less';
import {
  FacebookButton,
  GoogleButton,
  EmailButton,
} from '../../components/buttonLink/buttonLink';
import DefaultLayout from '../../layouts/default/defaultLayout';
import EmailForm from './email-form';
import { Link } from '@reach/router';
import PageCard from '../../components/pageCard/pageCard';

class Register extends React.PureComponent {
  state = {
    showEmailForm: false,
  };

  showEmailForm = () => {
    this.setState({
      showEmailForm: true,
    });
  };

  render() {
    const { showEmailForm } = this.state;
    return (
      <DefaultLayout pageTitle="Create account - Questup">
        <PageCard heading="Create account">
          {!showEmailForm && (
            <React.Fragment>
              <div className={styles.buttons}>
                <FacebookButton url={`${REST_API_HOST}/auth/facebook`}>
                  Sign up with Facebook
                </FacebookButton>
                <GoogleButton url={`${REST_API_HOST}/auth/google`}>
                  Sign up with Google
                </GoogleButton>
                <p className={styles.emailOptionDesc}>
                  Or sign up with your email address
                </p>
                <EmailButton onClick={this.showEmailForm}>
                  Use my email
                </EmailButton>
              </div>
            </React.Fragment>
          )}

          {showEmailForm && (
            <React.Fragment>
              <EmailForm />
            </React.Fragment>
          )}
          <p className={styles.termsOfService}>
            By signing up for Questup, you agree to the{' '}
            <Link className="textLink" to="/terms-of-service">
              Terms of Service.
            </Link>{' '}
            View our{' '}
            <Link className="textLink" to="/privacy">
              Privacy Policy.
            </Link>
          </p>
        </PageCard>
      </DefaultLayout>
    );
  }
}

Register.propTypes = {};

export default Register;
