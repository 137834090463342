import React from 'react';
import './home.less';
import Hero from './sections/hero/hero';
import HowItWorks from './sections/howItWorks/howItWorks';
import Mission from './sections/mission/mission';
import Try from './sections/try/try';
import UseCases from './sections/useCases/useCases';
import Footer from './sections/footer/footer';
import AppNavBar from '../../components/appNavBar/appNavBar';

function HomePage() {
  return (
    <React.Fragment>
      <AppNavBar />
      <Hero />
      <HowItWorks />
      <Mission />
      <Try />
      <UseCases />
      <Footer />
    </React.Fragment>
  );
}

export default HomePage;
