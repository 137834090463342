import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Presentation from '../../components/presentationPage/presentationPage';
import styles from './presentationPage.less';
import { Expo, TweenMax } from 'gsap';
import eventWebsocket from '../../utils/EventWebsocket';
import { Helmet } from 'react-helmet';

const PresentationPage = ({ eventId }) => {
  const [upcomingQuestions, setUpcomingQuestions] = useState();
  const [event, setEvent] = useState();
  const [highlightedQuestion, setHighlightedQuestion] = useState();
  const [loadingComplete, setLoadingComplete] = useState(false);
  const loadingOverlayRef = useRef();
  const eventWebsocketRef = useRef();
  const upcomingQuestionsRef = useRef();
  upcomingQuestionsRef.current = upcomingQuestions;

  useEffect(() => {
    initWebsocket();
    //initLoadingAnimation();
    return () => {
      eventWebsocketRef.current.stop();
    };
  }, []);

  useEffect(() => {
    if (loadingComplete) {
      TweenMax.to(loadingOverlayRef.current, 1, {
        opacity: 0,
        ease: Expo.easeOut,
      });
    }
  }, [loadingComplete]);

  const initWebsocket = () => {
    const config = {
      eventId,
      onEventDetailsChange: (data) => {
        setEvent(data);
        setLoadingComplete(true);
      },
      onHighlightQuestion: (question) => {
        if (!question) {
          setHighlightedQuestion(undefined);
          setUpcomingQuestions([]);
        } else {
          setHighlightedQuestion(question);
          if (upcomingQuestionsRef.current) {
            setUpcomingQuestions(
              upcomingQuestionsRef.current.filter((q) => q.id !== question.id)
            );
          }
        }
      },
      onQuestionsChange: (data) => {
        setUpcomingQuestions(data.filter((q) => !q.active && !q.archived));
      },
    };
    eventWebsocketRef.current = new eventWebsocket(config);
    eventWebsocketRef.current.start();
  };

  return (
    <div>
      <div className={styles.loadingAnimation} ref={loadingOverlayRef}>
        <Helmet>
          <title>{`${event?.name} - Questup`}</title>
        </Helmet>
        <div className={styles.loader}>
          Loading <span className={styles.dotOne}>.</span>
          <span className={styles.dotTwo}>.</span>
          <span className={styles.dotThree}>.</span>
        </div>
      </div>
      {loadingComplete && (
        <Presentation
          eventPinCode={event.code}
          eventName={event.name}
          eventId={eventId}
          eventSlogan="Be engaged - ask a question"
          questionOnAir={highlightedQuestion}
          upcomingQuestions={upcomingQuestions}
        />
      )}
    </div>
  );
};

PresentationPage.propTypes = {
  eventId: PropTypes.string,
};

export default PresentationPage;
