import React from 'react';
import section from '../../homePage.less';
import styles from './mission.less';
import photo from '../../../../graphics/photo-we-believe.jpg';
import photoWebp from '../../../../graphics/photo-we-believe.webp';

const SectionMission = () => {
  return (
    <section className={styles.mission}>
      <div className={section.sectionContentlarge}>
        <div className={styles.content}>
          <picture className={styles.image}>
            <source
              type="image/webp"
              srcSet={photoWebp}
              width="606"
              height="404"
            />
            <img
              loading="lazy"
              className={styles.image}
              src={photo}
              alt=""
              width="606"
              height="404"
            />
          </picture>
          <div>
            <h2 className={styles.heading}>We believe</h2>
            <p className={styles.leadParagraph}>
              Questions are crucial for understanding. It’s the basis of all
              conversations and a tool for learning. We believe that all people
              should be able to ask questions. With Questup everyone gets a way
              to get answers, regardless if you are the shy guy in the corner or
              the confident girl at the front row.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SectionMission;
